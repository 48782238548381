import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    d_day: 0,
    pay_date: null,
    end_date: null,
    is_first: false,
    mall_id: null,
    payment_method: null,
    script_src: null,
    shop_no: 1,
    start_date: null,
    use_regular_payment: false,
    version: null,
};

const paymentInfoSlice = createSlice({
    name: "globalLoading",
    initialState: initialState,
    reducers: {
        setPaymentVersion: (state, action) => {
            Object.assign(state, { ...action.payload });
        },
    },
});

export const { setPaymentVersion } = paymentInfoSlice.actions;
export default paymentInfoSlice.reducer;
